<template>
	<span class="" v-if="src">
		<el-image class="image" :src="src" @click="preview"></el-image>
		<components-preview v-if="preview_visible" :images="preview_image" @closeViewer="cancelPreview"/>
	</span>
</template>

<script >
import ComponentsPreview from '@/components/admin/form/upload/Preview.vue'

export default {
	components:{ComponentsPreview},
	props:{
		src:"",
	},
	data(){
		return {
			preview_visible:false,
			preview_image:[]
		}
	},
	methods:{
		preview(){
			this.preview_image = [src];
			this.preview_visible = true;
		},
		cancelPreview(){
			this.preview_image = [];
			this.preview_visible = false;
		}
	}
}
</script>

<style lang="scss" scoped>
.image{width:50px;height:50px;background: #efefef;cursor: pointer;
	::v-deep{
		img{object-position: center center;object-fit: contain;}
	}
}
</style>
