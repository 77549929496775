<template>
	<span>{{value ? formatTime(value,format) : '-'}}</span>
</template>

<script>
import moment from "moment"
export default {
	props:{
		value:"",
		format:{
			type:String,
			default:"YYYY-MM-DD HH:mm:ss"
		}
	},
	methods:{
		formatTime(value,format){
			return moment.unix(value).format(format)
		}
	}
}
</script>

