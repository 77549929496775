import jsCookie from "js-cookie"
import {site_config} from "@/config/index.js";
export default {
	namespaced: true,
	state: {
		token:"",
		error:{},
		user_info:{}
	},
	getters:{
		getToken(state){
			let token = state.token;
			if(!token){
				token = jsCookie.get(`${site_config.cookie_prefix}_view_token`) ;
			}
			return token;
		}
	},
	mutations: {
		setToke(state,token){
			state.token = token;
			jsCookie.set(`${site_config.cookie_prefix}_view_token`,token)
		},
		setError(error){
			this.error = error;
		},
		setUserInfo(state,user_info){
			state.user_info = user_info;
		}
	},
}
