<template>
	<el-select class="el-block form-tag-input" v-model="modelValue" :reserve-keyword="true" :filterable="true" :allow-create="true" :multiple="true" :default-first-option="true" :popper-append-to-body="false" placeholder="输入内容，用回车添加" >
		<el-option v-for="(item,index) in option" :key="index" :label="item[title] || item" :value="item[id] || item"></el-option>
	</el-select>
</template>

<script >
export default {
	props:{
		value:"",
		option:{
			default:[],
			type:Array
		},
		id:{
			default:'id',
			type:String,
		},
		title:{
			default:'title',
			type:String,
		}
	},
	computed:{
		modelValue:{
			get(){
				return this.value ? this.value.split(',') : []
			},
			set(value){
				this.$emit('input',value.join(','))
			}
		}
	}
}
</script>
