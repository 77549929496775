<template>
	<div class="list">
		<div class="item" v-for="(item,index) in modelValue" :key="index">
			<template v-for="(v,i) in field">
				<el-input v-if="v.type === 'input'" :value="item[v.field]" @input="setAttr($event,index,v)" :key="i" >
					<template slot="prepend">{{v.prop}}</template>
				</el-input>
				<el-input v-if="v.type === 'textarea'" type="textarea" :value="item[v.field]" @input="setAttr($event,index,v)" :key="i" >
					<template slot="prepend">{{v.prop}}</template>
				</el-input>
			</template>
			<el-button class="el-icon-close close" @click="remove(index)"></el-button>
		</div>
		<el-button @click="add" >添加</el-button>
	</div>
</template>

<script >
export default {
	props:{
		value:"",
		field:{
			type:Array,
			default:()=>{
				return [
					{prop:"姓名",field:"username",type:"input"},
					{prop:"头衔",field:"position",type:"textarea"},
				]
			}
		},
	},
	computed:{
		modelValue:{
			get(){
				return this.value ? JSON.parse(this.value) : []
			},
			set(value){
				this.$emit('input',JSON.stringify(value))
			}
		}
	},
	methods:{
		setAttr(e,index,v){
			const data = _.cloneDeep(this.modelValue);
			data[index][v.field] = e;
			this.$emit('input',JSON.stringify(data))
		},
		remove(index){
			const data = _.cloneDeep(this.modelValue);
			data.splice(index,1);
			this.$emit('input',JSON.stringify(data))
		},
		add(){
			const d = {};
			this.field.map((item,index)=>{
				d[item.field] = "";
			})
			const list = _.cloneDeep(this.modelValue);
			list.push(d);
			this.$emit('input',JSON.stringify(list));
		}
	}
}
</script>
<style lang="scss" scoped>
.list{
	.item{display: flex;flex-wrap: wrap;align-items: flex-start;
		.el-input,.el-textarea{margin-right:7px;width: calc((100% - 7px*3 - 100px)/3);margin-bottom: 7px;}
		.close{margin-bottom: 7px;}
	}
}
</style>
