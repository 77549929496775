import {request} from '@/common/request/admin.js'

export const site = {
	upload(data){
		return request.post('/backend/upload/index',data,{
			headers: {
				'Content-Type' : 'multipart/form-data'
			},
			timeout:60*20*1000
		})
	},
	oss(params){
		return request.get('/backend/upload/oss',{params})
	}
}

export const attachment = {
	all(params){
		return request.get('/backend/attachment/all',{params})
	},
}

export const auth = {
	login(data){
		return request.post('/backend/auth/login',data)
	},
	logout(){
		return request.post('/backend/auth/logout')
	},
	getLoginInfo(){
		return request.post('/backend/auth/getLoginInfo')
	}
}

export const dashboard = {
	pv(params){
		return request.get('/backend/dashboard/pv',{params})
	},
}

export const admin = {
	get(params){
		return request.get('/backend/admin/get',{params})
	},
	all(params){
		return request.get('/backend/admin/all',{params})
	},
	create(data){
		return request.post('/backend/admin/create',data)
	},
	delete(data){
		return request.post('/backend/admin/delete',data)
	},
}

export const speaker = {
	get(params){
		return request.get('/backend/speaker/get',{params})
	},
	all(params){
		return request.get('/backend/speaker/all',{params})
	},
	create(data){
		return request.post('/backend/speaker/create',data)
	},
	delete(data){
		return request.post('/backend/speaker/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/speaker/changeOrder',data)
	},
	import(data){
		return request.post('/backend/speaker/import',data)
	}
}

export const plan = {
	get(params){
		return request.get('/backend/plan/get',{params})
	},
	all(params){
		return request.get('/backend/plan/all',{params})
	},
	create(data){
		return request.post('/backend/plan/create',data)
	},
	delete(data){
		return request.post('/backend/plan/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/plan/changeOrder',data)
	},
	import(data){
		return request.post('/backend/plan/import',data)
	}
}

export const video = {
	get(params){
		return request.get('/backend/video/get',{params})
	},
	all(params){
		return request.get('/backend/video/all',{params})
	},
	create(data){
		return request.post('/backend/video/create',data)
	},
	delete(data){
		return request.post('/backend/video/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/video/changeOrder',data)
	},
	import(data){
		return request.post('/backend/video/import',data)
	}
}

export const file = {
	get(params){
		return request.get('/backend/file/get',{params})
	},
	all(params){
		return request.get('/backend/file/all',{params})
	},
	create(data){
		return request.post('/backend/file/create',data)
	},
	delete(data){
		return request.post('/backend/file/delete',data)
	},
	changeOrder(data){
		return request.post('/backend/file/changeOrder',data)
	},
	import(data){
		return request.post('/backend/file/import',data)
	}
}

export const version = {
	push(data){
		return request.get('/backend/version/push',data)
	},
	create(data){
		return request.post('/backend/version/create',data)
	},
	all(params){
		return request.get('/backend/version/all',{params})
	},
}


