import axios from 'axios'
import {Message,MessageBox} from "element-ui"
import {host_config} from "@/config/index.js"
import {getAdminToken} from "@/service/admin/common.js";
import {isDev} from "@/common/other";
import store from "@/store";
import router from "@/router";

const requestInstance = axios.create({
	timeout: 30*1000,
})

requestInstance.interceptors.request.use(config => {
	let baseURL = host_config.api;
	if(isDev()){
		baseURL = '/proxy'
	}
	config.baseURL = baseURL;
	config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/json; charset=utf-8';
	config.headers['Authorization'] = getAdminToken();
	return config
}, error => {
	return Promise.reject(error)
})

requestInstance.interceptors.response.use(
	response => {
		let path,message;
		let res = response.data;
		switch (res.code){
			case 1:
				return Promise.resolve(res.data);
			case 403:
				if(response.config){
					path = response.config.baseURL + response.config.url;
				}
				message = `${res.msg || 'Error'} : ${path}`
				if(getAdminToken()){
					store.commit('admin/setToken','')
					MessageBox({
						message:"登录状态已失效,请重新登录",
						title:"提示",
						showClose:false,
						closeOnClickModal:false,
						closeOnPressEscape:false,
						closeOnHashChange:false,
					}).then(()=>{
						router.push('/admin/auth/login')
					})
				}
				return Promise.reject({code:res.code,message:message});
			default:
				message = `${res.msg || 'Error'}`
				Message.error(message)
				return Promise.reject({code:res.code,message:message});
		}
	},
	error => {
		let message = error.message;
		let response = "";
		if(error && error.response){
			response = error.response;
			let status = response.status;
			let statusText = response.statusText;
			let path = "";
			if(error.response.config){
				path =response.config.baseURL + response.config.url;
			}
			switch (status){
				case 500:
					message = `${statusText} : ${path}`;
					break;
				default:
					message = `${error.message || 'Error'} : ${path}`
					break;
			}
		}
		Message.error(message)
		return Promise.reject({code:response.status,message:message})
	}
)

export const request = requestInstance;
