import {site} from "@/api/admin";

export class uploadFile {
	constructor(loader) {
		this.loader = loader
	}

	async upload() {
		return this.loader.file.then( file => new Promise( ( resolve, reject ) => {
			var formData = new FormData();
			formData.append('file', file)
			site.upload(formData).then((res)=>{
				resolve({
					uploaded:1,
					default:res.url
				})
			}).catch((error)=>{
				reject()
			})
		}));
	}

	abort() {

	}
}
