<template>
	<div class="section">
		<slot></slot>
	</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.section{width: 1200px;margin: 0 auto;}
@media screen and (max-width: 1440px) {
	.section{padding: 0 115px;}
}
@media screen and (max-width: 1230px) {
	.section{width: auto;}
}
@media screen and (max-width: 992px) {
	.section{padding: 0 20px;}
}
</style>

